import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";

import { privacyBoxGrey } from "../css/privacy.module.css";

const Privacy = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Privacy | Pannal Village Hall</title>
      </Helmet>
      <div className="content-wrapper-column">
        <div className="page-header">
          <h1>Privacy - Terms</h1>
        </div>
        <div className={privacyBoxGrey}>
          <ReactMarkdown source={data.strapiPrivacy.privacyTerms} />
        </div>
      </div>
    </>
  );
};

export default Privacy;

export const query = graphql`
  {
    strapiPrivacy {
      id
      privacyTerms
    }
  }
`;
